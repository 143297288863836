<template>
  <div>
    <div class="nav d-flex nav-tabs" id="nav-tab" role="tablist">
      <button class="nav-link flex-fill" id="nav-exact-tab" :class="{'active': calendarType === 'exactDates'}"
      data-bs-toggle="tab" data-bs-target="#nav-exact" type="button"
      role="tab" aria-controls="nav-exact" aria-selected="true" ref="exactDatesElement">
        {{$t("sabre.search-panel.exact-dates")}}</button>

      <button class="nav-link flex-fill" id="nav-range-tab" :class="{'active': calendarType === 'specificDateRange'}"
      data-bs-toggle="tab" data-bs-target="#nav-range" type="button"
      role="tab" aria-controls="nav-range" aria-selected="false" ref="specificDateRangeElement">
        {{$t("sabre.search-panel.date-range")}}</button>

      <button class="nav-link flex-fill" id="nav-full-tab" :class="{'active': calendarType === 'fullMonthDateRange'}"
      data-bs-toggle="tab" data-bs-target="#nav-full" type="button"
      role="tab" aria-controls="nav-full" aria-selected="false" ref="fullMonthDateRangeElement">
        {{$t("sabre.search-panel.full-month")}}</button>
    </div>
    <div class="tab-content" id="nav-tabContent">
      <select-exact-date @close="closeDropdown" />
      <select-date-range @close="closeDropdown" />
      <select-full-months @close="closeDropdown" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'date-picker-tabs',
  components: {
    SelectExactDate: () => import('./selectExactDate'),
    SelectDateRange: () => import('./selectDateRange'),
    SelectFullMonths: () => import('./selectFullMonths'),
  },
  computed: {
    ...mapGetters({
      device: 'GET_DEVICE',
      calendarTypeList: 'GET_SABRE_CALENDAR_TYPE_LIST',
      calendarType: 'GET_SABRE_CALENDAR_TYPE',
    }),
  },
  data() {
    return {
    };
  },
  mounted() {
    const vm = this;
    if (this.calendarType) {
      const targetElement = this.$refs[`${this.calendarType}Element`];
      new window.bootstrap.Tab(targetElement).show();
    } else {
      this.$store.commit('SET_SABRE_CALENDAR_TYPE', this.calendarTypeList[0]);
    }
    const exactElement = this.$refs.exactDatesElement;
    const rangeElement = this.$refs.specificDateRangeElement;
    const fullElement = this.$refs.fullMonthDateRangeElement;
    exactElement.addEventListener('shown.bs.tab', () => {
      vm.$store.commit('SET_SABRE_CALENDAR_TYPE', vm.calendarTypeList[0]);
    });
    rangeElement.addEventListener('shown.bs.tab', () => {
      vm.$store.commit('SET_SABRE_CALENDAR_TYPE', vm.calendarTypeList[1]);
    });
    fullElement.addEventListener('shown.bs.tab', () => {
      vm.$store.commit('SET_SABRE_CALENDAR_TYPE', vm.calendarTypeList[2]);
    });
  },
  methods: {
    closeDropdown() {
      this.$emit('close');
    },
  },
};

</script>
